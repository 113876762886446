import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';

import { HeroSectionContainer, HeroSectionContent } from './heroSection.styled';
import SearchBox from './searchBox/searchBox';

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <WidthContainer noBackground>
        <HeroSectionContent>
          <div>
            {/* <source srcSet={pataDogWebp} type="image/webp" /> */}
            <Image
              src={`${process.env.CDN_BASE_URL}/images/image-dog-with-tongue-out.webp`}
              alt="Happy dog with togue out"
              height={1000}
              width={1000}
            />
          </div>
          <SearchBox />
        </HeroSectionContent>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
