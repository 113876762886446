import { Button } from '@pata-app/core';
import { Icon } from '@pata-app/icons';

import React from 'react';

interface ILoadingButton extends React.ComponentProps<typeof Button> {
  isLoading: boolean;
  children: React.ReactNode;
}

const LoadingButton = ({ isLoading, children, ...rest }: ILoadingButton) => {
  return (
    <Button {...rest} isDisabled={isLoading}>
      {isLoading ? <Icon type="Loader" /> : children}
    </Button>
  );
};

export default LoadingButton;
