import styled from 'styled-components';

export const HeroSectionContainer = styled.section`
  background: ${({ theme }) => theme.colors.secondary.yellow};
`;

export const HeroSectionContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.sizing[1]}px;

  flex-shrink: 0;
  flex-basis: 50%;
  flex-grow: 1;
  object-fit: contain;

  margin: auto;
  width: 100%;

  max-width: 1500px;

  padding: ${({ theme }) => theme.sizing[3]}px ${({ theme }) => theme.sizing[3]}px 0 ${({ theme }) => theme.sizing[3]}px;

  > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      width: 90%;
    }
  }

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    flex-direction: row;
    justify-content: space-between;

    height: 400px;

    padding: 0;

    > div {
      flex: 1 0 50%;
    }

    > div:first-of-type {
      img {
        width: 100%;
        height: 90%;
        object-fit: contain;
        object-position: bottom;
      }
    }
  }

  @media ${({ theme }) => `${theme.breakpoints.l}`} {
    height: 500px;
  }
`;
