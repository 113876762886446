import React from 'react';

import { THightlight } from '../../highlights.types';
import { HighlightContainer } from './highlight.styled';

interface IHighlight {
  highlight: THightlight;
}

const Highlight = ({ highlight }: IHighlight) => {
  return (
    <HighlightContainer>
      <img src={highlight.image} alt={highlight.altText} />
      <h3>{highlight.title}</h3>
      <p>{highlight.description}</p>
    </HighlightContainer>
  );
};

export default Highlight;
