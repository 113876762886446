export const highlights = [
  {
    id: '1',
    title: 'All about Pets',
    description:
      'At our core, we are focused to enrich the lives of every pet by providing accessible, helpful and trustworthy resources and services.',
    image: `https://pata.pet/cdn-cgi/image/width=500,height=500,format=auto/${process.env.CDN_BASE_URL}/images/illustration-happy-dog.png`,
    altText: 'Illustration of happy dog with tongue out',
  },
  {
    id: '2',
    title: 'Connection',
    description:
      'We seamlessly connect pets, pet parents and pet businesses on one trusted platform that centralises services, care and community.',
    image: `https://pata.pet/cdn-cgi/image/width=500,height=500,format=auto/${process.env.CDN_BASE_URL}/images/illustration-speakerphone.png`,
    altText: 'Illustration of speakerphone',
  },
  {
    id: '3',
    title: 'Stress-free Parents',
    description:
      'Pata is your partner along the pet parenting journey, providing support, tips, and a community dedicated to making every step easier.',
    image: `https://pata.pet/cdn-cgi/image/width=500,height=500,format=auto/${process.env.CDN_BASE_URL}/images/illustration-owner.png`,
    altText: 'Illustration of happy pet parent',
  },
];
