import styled from 'styled-components';

export const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  img {
    height: 100px;
  }

  h3 {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
    margin: 16px 0;

    text-align: center;

    font-family: ${({ theme }) => theme.fontFamily.semiBold};
  }
  p {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
    margin: 0;

    text-align: center;
  }

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    width: 40%;
  }

  @media ${({ theme }) => `${theme.breakpoints.m}`} {
    width: 250px;
  }
`;
