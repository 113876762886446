/* eslint-disable no-empty */
import axios, { Method } from 'axios';

interface IRequest {
  endpoint: string;
  method: Method;
  body?: any;
  header?: Record<string, string>;
}

const request = async ({ endpoint, method, body, header }: IRequest) => {
  try {
    return await axios({
      method,
      url: endpoint,
      headers: { ...header },
      data: body,
    });
  } finally {
  }
};

export default request;
