import { Button } from '@pata-app/core';

import React from 'react';
import { useTheme } from 'styled-components';

import TextAndImageSectionWithBackground from '../../../../components/textAndImageSectionWithBackground/textAndImageSectionWithBackground';
import { BusinessPartnerDescription } from './businessPartner.styled';

const BusinessPartner = () => {
  const theme = useTheme();

  return (
    <TextAndImageSectionWithBackground
      imageSrc={`${process.env.CDN_BASE_URL}/images/image-man-with-dog.webp`}
      imageTextAlt="Man holding and kissing dog"
      backgroundColour={theme.core.colors.secondary.green}
      textAlignment="right"
    >
      <BusinessPartnerDescription>
        <h2>
          <span>Own a Pet Business?</span>
          <span>Bring it online.</span>
        </h2>

        <h3>Be discovered by more customers to grow your business</h3>
        <p>
          Managing your online presence is now stress-free with Pata. Our platform is designed specifically for pet
          businesses, whether you're established or just starting out. We make it simple to connect with pet parents and
          meet your growth targets.
        </p>
        <p>Join Pata for free and see how we can boost your business.</p>

        <a href={`${process.env.WEB_BASE_URL}/business/sign-up`}>
          <Button>Partner with Pata</Button>
        </a>
      </BusinessPartnerDescription>
    </TextAndImageSectionWithBackground>
  );
};

export default BusinessPartner;
