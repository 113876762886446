import { appTheme as designSystemAppTheme } from '@pata-app/themes';

const colors = {
  black: '#000000',
  white: '#FFFFFF',

  // Alerts
  error: '#D32F2F',
  info: '#0074e6',
  lightBlue: '#e5f2ff',
  lightGreen: '#eaf6ed',
  orange: '#f57f17',
  success: '#2e7b32',
  successAlt: '#00a33c',
  warning: '#fdd835',

  // Greyscale
  darkGrey: '#4b4b4b',
  grey: '#757575',
  lightGrey: '#f2f2f2',
  lighterGrey: '#f5f5f5',
  mediumGrey: '#d8d8d8',

  // Pata Colours
  pataBlue: '#117191',
  pataGreen: '#8cc4b8',
  pataTeal: '#90cfd1',
  pataOrange: '#f5a046',
  pataYellow: '#f5c14f',
};

const sizes = {
  s: '640px',
  m: '768px',
  l: '1024px',
  xl: '1280px',
  xxl: '1600px',
  xxxl: '1984px',
};

const fontSizes = {
  xxs: 8,
  xs: 10,
  s: 12,
  sm: 14,
  m: 16,
  ml: 18,
  l: 20,
  xl: 24,
  xxl: 28,
  xxxl: 32,
};

const fontWeight = {
  regular: 300,
  semiBold: 600,
  bold: 700,
};

const fontFamily = {
  light: 'SourceSansPro, sans-serif',
  regular: 'SourceSansPro, sans-serif',
  semiBold: 'Jost, sans-serif',
  bold: 'SourceSansPro, sans-serif',
};

export const appTheme = {
  ...designSystemAppTheme,
  colors: {
    primary: {
      blue: colors.pataBlue,
      green: colors.pataGreen,
      teal: colors.pataTeal,
    },
    secondary: {
      orange: colors.pataOrange,
      yellow: colors.pataYellow,
    },
    greyscale: {
      white: colors.white,
      lighter: colors.lighterGrey,
      light: colors.lightGrey,
      medium: colors.mediumGrey,
      dark: colors.grey,
      darker: colors.darkGrey,
      black: colors.black,
    },
    info: {
      light: colors.lightBlue,
      medium: colors.info,
    },
    success: {
      light: colors.lightGreen,
      medium: colors.successAlt,
      dark: colors.success,
    },
    warning: {
      medium: colors.warning,
      dark: colors.orange,
    },
    danger: {
      medium: colors.error,
    },
  },
  breakpoints: {
    xs: `(min-width: ${sizes.s})`,
    s: `(min-width: ${sizes.s})`,
    m: `(min-width: ${sizes.m})`,
    l: `(min-width: ${sizes.l})`,
    xl: `(min-width: ${sizes.xl})`,
    xxl: `(min-width: ${sizes.xxl})`,
    xxxl: `(min-width: ${sizes.xxxl})`,
  },
  fontSizes,
  fontFamily,
  fontWeight,
  sizing: [4, 8, 16, 20, 32, 40],
  border: {
    container: {
      weight: 1,
      radius: 10,
      style: 'solid',
    },
    element: {
      weight: 1,
      radius: 3,
      style: 'solid',
    },
  },
};

export type TAppTheme = typeof appTheme;
