import styled from 'styled-components';

export const HeroSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => theme.core.sizing[3]}px ${({ theme }) => theme.core.sizing[4]}px 0
    ${({ theme }) => theme.core.sizing[4]}px;

  background: ${({ theme }) => theme.core.colors.primary.blue};
  color: ${({ theme }) => theme.core.colors.greyscale.white};

  text-align: center;

  h1 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.s}px;
  }

  h2 {
    margin-top: ${({ theme }) => theme.core.sizing[1]}px;

    font-family: ${({ theme }) => theme.core.fontFamily.semiBold};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
  }

  img {
    width: 80%;
  }

  @media ${({ theme }) => `${theme.breakpoints.m}`} {
    img {
      width: 500px;
    }
  }
`;

export const ContactTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  margin: 0 ${({ theme }) => theme.core.sizing[4]}px;

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    width: 100%;
    max-width: 400px;
  }

  @media ${({ theme }) => `${theme.breakpoints.l}`} {
    flex-direction: row;

    width: 80%;
    max-width: none;

    margin: auto;
  }
`;

export const ContactUsDescription = styled.p`
  margin: 0;
  width: 90%;

  text-align: center;

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    width: 80%;
  }

  @media ${({ theme }) => `${theme.breakpoints.l}`} {
    width: 60%;
  }
`;

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;

  margin: 60px auto;

  @media ${({ theme }) => `${theme.breakpoints.m}`} {
  }
`;
