import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';

import { CharityPartnerContainer, BusinessPartnerDescription, BusinessPartnerImage } from './charityPartners.styled';

const CharityPartners = () => {
  return (
    <CharityPartnerContainer>
      <WidthContainer noBackground>
        <BusinessPartnerImage>
          <Image
            src={`${process.env.CDN_BASE_URL}/images/illustration-dogs-and-cat.webp`}
            alt="Illustration of a group of cats and dogs"
            height={1000}
            width={1000}
          />
        </BusinessPartnerImage>
        <BusinessPartnerDescription>
          <h2>
            <span>Working together</span>
            <span>with Pet Charities.</span>
          </h2>

          <h3>We give back to pets and animals in need</h3>
          <p>
            We partner with charities assisting pets in need. If your organisation is dedicated to animal welfare and
            interested in collaborating, please get in touch, we would love to hear from you. By working together, we
            can make a positive impact for animals requiring additional care and compassion in our community.
          </p>

          {/* <a href={`${process.env.WEB_BASE_URL}/contact-us`}>
            <Button>Get in Touch</Button>
          </a> */}
        </BusinessPartnerDescription>
      </WidthContainer>
    </CharityPartnerContainer>
  );
};

export default CharityPartners;
