import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import AboutUs from '../pages/aboutUs/aboutUs';
import ContactUs from '../pages/contactUs/contactUs';
import Error from '../pages/error/error';
import Home from '../pages/home/home';
import PrivacyPolicy from '../pages/privacyPolicy/privacyPolicy';
import TermsAndConditions from '../pages/termsAndConditions/termsAndConditions';
import '../styles/fonts.css';
import GlobalStyle from '../styles/globalStyles';
import { appTheme } from '../styles/theme';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/error" element={<Error />} />
            <Route path="/not-found" element={<Error errorMessage="Not Hound!" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
