import { Footer, Header } from '@pata-app/core';

import React from 'react';

// import Footer from '../footer/footer';
// import Header from '../header/header';
import { MainContainer } from './appWrapper.styled';
import { IAppWrapper } from './appWrapper.types';

const AppWrapper = ({ children }: IAppWrapper) => {
  return (
    <>
      <Header pataProLink={`${process.env.PRO_SIGN_UP_BASE_URL}/business/sign-up`} />
      <MainContainer>{children}</MainContainer>
      <Footer baseUrl={process.env.WEB_BASE_URL} />
    </>
  );
};

export default AppWrapper;
