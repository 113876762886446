import { Method } from 'axios';

import { endpoints, methods, request } from '../../../../lib/api';
import { TNewsletterSubscriptionBody } from './newsletterSubscription.types';

export const postNewsletterRequest = async (newsletterSubscription: TNewsletterSubscriptionBody) => {
  const data = await request({
    endpoint: endpoints.newsletter,
    method: methods.POST as Method,
    body: newsletterSubscription,
  });

  return data.data.data;
};
