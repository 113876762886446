export const ContactUsConfig = [
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-lady-with-dogs.webp`,
    title: 'General Enquiry',
    description:
      'Have a question about Pata or what we do? Get in touch and one of our team will do their best to answer you question.',
    emailLink: 'info@team-pata.com',
  },
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-cat-and-dog-background.webp`,
    title: 'For Business',
    description:
      'Questions about onboarding your pet business onto Pata? Want to discuss new partnership opportunities? Our team is here.',
    emailLink: 'business@team-pata.com',
  },
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-dog-being-brushed.webp`,
    title: 'Support',
    description: "Experiencing an issue with our app? We're here to help get you back on track, so send us a message.",
    emailLink: 'support@team-pata.com',
  },
];
