import generateQueryString from '../utils/generateQueryString';

const endpoints = {
  businessProfile: (businessId: string) => `${process.env.API_ROOT_URL}/businesses/${businessId}`,
  businessReviews: (businessId: string) => `${process.env.API_ROOT_URL}/businesses/${businessId}/reviews`,
  search: (queryStrings: Record<string, string | number>) =>
    `${process.env.API_ROOT_URL}/businesses/search${generateQueryString(queryStrings)}`,
  config: `${process.env.API_ROOT_URL}/config/business`,
  newsletter: `${process.env.API_ROOT_URL}/marketing/newsletter/subscribe`,
};

export default endpoints;
