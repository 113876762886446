import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`

  ${normalize}
  html {
    scroll-behavior: smooth;
    background: ${({ theme }) => theme.colors.greyscale.white};
  }

  body{

    color: ${({ theme }) => theme.core.colors.greyscale.black};
    
    >div {
      display: flex;
      flex-direction: column;

      min-height: 100vh;

    }

    p {
      line-height: 1.5;
    }
  }

  && {
    * {
      box-sizing: border-box;
      font-family: 'Open Sans', sans-serif;

    }

  }


`;

export default GlobalStyle;
