import React from 'react';

import Highlight from './component/highlight/highlight';
import { highlights } from './highlights.config';
import { HighlightsContainer, HighlightsSectionContainer } from './highlightsSection.styled';

const HighlightsSection = () => {
  return (
    <HighlightsSectionContainer>
      <h2>Behind the best for your pet</h2>
      <HighlightsContainer>
        {highlights.map((highlight) => (
          <Highlight key={highlight.id} highlight={highlight} />
        ))}
      </HighlightsContainer>
    </HighlightsSectionContainer>
  );
};

export default HighlightsSection;
