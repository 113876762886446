import styled from 'styled-components';

export const BusinessPartnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    flex-direction: row;

    height: 600px;

    background: ${({ theme }) => theme.core.colors.secondary.green};
  }
`;

export const BusinessPartnerDescriptionBackground = styled.div`
  display: none;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 50%;

    height: 80%;

    margin: auto;

    > div {
      width: 100%;
      max-width: 1000px;
      height: 100%;

      background: ${({ theme }) => theme.core.colors.greyscale.white};
    }
  }
`;

export const BusinessPartnerDescription = styled.div`
  display: flex;

  flex-direction: column;

  > h2 {
    display: flex;
    flex-direction: column;

    margin-top: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.semiBold};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    }
  }

  > p {
    line-height: 1.4;
  }

  button {
    height: 45px;
    width: 100%;

    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: ${({ theme }) => theme.core.sizing[4]}px ${({ theme }) => theme.core.sizing[4]}px
      ${({ theme }) => theme.core.sizing[4]}px 64px;

    button {
      width: auto;
      height: 36px;
    }

    a {
      align-self: flex-end;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    a {
      align-self: flex-start;
    }
  }
`;

export const BusinessPartnerImage = styled.div`
  height: 255px;

  overflow: hidden;
  background: ${({ theme }) => theme.core.colors.secondary.green};

  img {
    width: 100%;
    height: 100%;
    max-width: 1200px;

    object-fit: cover;
    object-position: left;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    flex: 1 0 50%;

    height: auto;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;

    margin: 32px;
    padding: 64px 0 32px 0;

    border-top: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    position: absolute;
    justify-content: center;
\
    width: 100%;
    height: 100%;

    > div {
      align-items: center;
      justify-content: flex-start;

      padding: 0;

      height: 100%;

      border-top: none;
    }
  }
`;
