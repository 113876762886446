import { Image, WidthContainer } from '@pata-app/core';

import React, { useState } from 'react';

import LoadingButton from '../../../../components/loadingButton/loadingButton';
import { usePostNewsletterSubscription } from './newlettersSubscription.queries';
import {
  ImageContainer,
  Input,
  Message,
  NewsletterContainer,
  SectionContent,
  SignUpContainer,
} from './newsletterSubscription.styled';

const NewsletterSubscription = () => {
  const [email, setEmail] = useState('');

  const [message, setMessage] = useState({ text: '', type: '' });

  const mutation = usePostNewsletterSubscription();

  const handleSubmitNewsletter = () => {
    if (email.length < 1) {
      setMessage({ text: 'Email is required', type: 'danger' });

      return;
    }

    if (!email.match(/[^@]+@[^@]+\.[^@]+/)) {
      setMessage({ text: 'Not a valid email address', type: 'danger' });

      return;
    }

    mutation.mutate(
      { email },
      {
        onError: () => {
          setMessage({
            text: 'Something went wrong.',
            type: 'danger',
          });
        },
        onSuccess: () => {
          setMessage({
            text: '',
            type: '',
          });
        },
      },
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  return (
    <NewsletterContainer id="newsletter">
      <WidthContainer>
        <SectionContent>
          <h2>
            <span>Hear the latest news about Pata</span>
          </h2>

          <p>
            Join the Pata community, subscribe to our newsletter for exclusive sneak peeks at new developments and
            offerings.
          </p>

          <Message type={message.type}>{message.text}</Message>

          <SignUpContainer>
            <Input placeholder="Please enter email" value={email} onChange={handleInputChange} />

            <LoadingButton onClick={handleSubmitNewsletter} isLoading={mutation.isLoading}>
              {mutation.isSuccess ? "You're signed up!" : 'Sign up for Newsletter'}
            </LoadingButton>
          </SignUpContainer>
        </SectionContent>
        <ImageContainer>
          <Image src={`${process.env.CDN_BASE_URL}/images/image-ipad-app.webp`} alt="iPad showing Pata Pet website" />
        </ImageContainer>
      </WidthContainer>
    </NewsletterContainer>
  );
};

export default NewsletterSubscription;
