import styled from 'styled-components';

export const NewsletterContainer = styled.section`
  > div > div {
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 100%;

      * > {
        flex: 1 0 50%;
      }
    }
  }
`;

export const BusinessPartnerDescriptionBackground = styled.div`
  display: none;

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    display: flex;
    align-items: center;

    height: 80%;

    margin: auto;
    background: ${({ theme }) => theme.core.colors.greyscale.white};
  }
`;

export const SectionContent = styled.div`
  padding: 64px 32px;

  > h2 {
    display: flex;
    flex-direction: column;

    margin-top: 0;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.semiBold};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    }
  }

  > p {
    line-height: 1.4;
  }

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    padding: 100px 0;
  }
`;

export const ImageContainer = styled.div`
  display: none;

  overflow: hidden;

  img {
    width: 60%;
    object-fit: cover;

    transform: scale(1.2) translateY(40px) translateX(-35px);
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    justify-content: center;
    align-self: center;

    flex: 1 0 50%;

    img {
      width: 100%;
      object-fit: cover;

      transform: none;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    justify-content: flex-end;
    align-self: flex-end;
    flex: 1 0 50%;

    img {
      width: 60%;
      object-fit: cover;

      transform: scale(1.2) translateY(40px) translateX(-35px);
    }
  }
`;
export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.core.sizing[3]}px;

  input {
    flex-grow: 1;

    height: 45px;

    background: ${({ theme }) => theme.core.colors.greyscale.white};
  }

  button {
    height: 45px;
  }

  @media ${({ theme }) => `${theme.breakpoints.s}`} {
    gap: ${({ theme }) => theme.core.sizing[2]}px;
    flex-direction: column;

    input {
      flex-grow: 1;
    }

    button {
      font-weight: 700;
    }

    button,
    input {
      height: 36px;
    }
  }

  @media ${({ theme }) => `${theme.breakpoints.l}`} {
    flex-direction: row;
  }
`;

export const Input = styled.input`
  background: #8cc4b866;
  border: 1px solid ${({ theme }) => theme.colors.greyscale.white};
  border-radius: 0 0 6px 0;
`;

export const Message = styled.p`
  color: ${({ theme, type }) =>
    type === 'danger' ? theme.core.colors.danger.medium : theme.core.colors.greyscale.medium};
  font-size: ${({ theme }) => theme.core.fontSizes.s}px;
  font-weight: 700;
`;
