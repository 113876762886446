import styled from 'styled-components';

export const HighlightsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.sizing[2]}px;

  padding: 64px 0 ${({ theme }) => theme.sizing[3]}px 0;

  margin: auto;
  width: 100%;

  max-width: 1500px;

  h2 {
    margin: 0;
    padding: 0 32px;

    font-family: ${({ theme }) => theme.core.fontFamily.semiBold};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    color: ${({ theme }) => theme.core.colors.greyscale.black};
  }

  @media ${({ theme }) => `${theme.breakpoints.m}`} {
    width: 100%;
  }

  @media ${({ theme }) => `${theme.breakpoints.l}`} {
    width: 90%;

    padding: 80px 0 ${({ theme }) => theme.sizing[5]}px 0;
  }
`;

export const HighlightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: ${({ theme }) => theme.sizing[2]}px;

  padding: ${({ theme }) => theme.sizing[3]}px;

  @media ${({ theme }) => `${theme.breakpoints.m}`} {
    flex-direction: row;
    justify-content: space-around;

    width: 80%;

    padding: ${({ theme }) => theme.sizing[5]}px 0;
  }
`;
