import { useMutation } from 'react-query';

import { postNewsletterRequest } from './newlettersSubscription.api';
import { TNewsletterSubscriptionBody } from './newsletterSubscription.types';

export const usePostNewsletterSubscription = () => {
  return useMutation('postNewsletterSubscription', {
    mutationFn: (data: TNewsletterSubscriptionBody) => postNewsletterRequest(data),
  });
};
