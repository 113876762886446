import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';
import { useTheme } from 'styled-components';

import AppWrapper from '../../components/appWrapper/appWrapper';
import TextAndImageSection from '../../components/textAndImageSectionWithBackground/textAndImageSectionWithBackground';
import { MissionTileConfig } from './aboutUs.config';
import {
  AboutUsDescription,
  CharityContainer,
  ContactTileContainer,
  HeroSectionContainer,
  SectionHeader,
} from './aboutUs.styled';
import MissionTile from './components/missionTile/missionTile';
import OurStory from './components/ourStory/ourStory';

const AboutUs = () => {
  const theme = useTheme();

  return (
    <AppWrapper>
      <HeroSectionContainer>
        <div>
          <Image src={`${process.env.CDN_BASE_URL}/images/image-upsidedown-dogs.webp`} alt='' />
        </div>
        <h1>ABOUT US</h1>
        <h2>We are your Pet Friendly App</h2>
      </HeroSectionContainer>

      <WidthContainer noBackground>
        <AboutUsDescription>
          At Pata, pets come first in everything we do. Our app was designed by pet parents, for pet parents, with the
          aim of simplifying pet parenting and spreading joy throughout your journey together. Launched in 2023, we saw
          a need for an all-in-one digital solution that unites pet resources, services, advice and community. We
          believe pet parenthood should be easier, less stressful, and full of moments that make tails wag!
        </AboutUsDescription>

        <SectionHeader>Our Mission</SectionHeader>

        <ContactTileContainer>
          {MissionTileConfig.map((missionTile) => (
            <MissionTile
              key={missionTile.title}
              imageUrl={missionTile.imageUrl}
              title={missionTile.title}
              description={missionTile.description}
            />
          ))}
        </ContactTileContainer>
      </WidthContainer>

      <TextAndImageSection
        imageSrc={`${process.env.CDN_BASE_URL}/images/image-ipad-app.webp`}
        backgroundColour={theme.core.colors.secondary.yellow as string}
      >
        <OurStory />
      </TextAndImageSection>

      <WidthContainer noBackground>
        <CharityContainer>
          <div>
            <h2>
              <span>Working together</span>
              <span>with Pet Charities</span>
            </h2>

            <p>
              We partner with charities assisting pets in need. If your organisation is dedicated to animal welfare and
              interested in collaborating, please get in touch, we would love to hear from you. By working together, we
              can make a positive impact for animals requiring additional care and compassion in our community.
            </p>
          </div>
          <div>
            <Image
              src={`${process.env.CDN_BASE_URL}/images/illustration-dogs-and-cat.webp`}
              alt="Illustration of dogs with a cat"
            />
          </div>
        </CharityContainer>
      </WidthContainer>
    </AppWrapper>
  );
};

export default AboutUs;
