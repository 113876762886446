import React, { useEffect, useRef, useState } from 'react';

import useOnClickOutside from '../../hooks/useClickOutside';
import useDebounce from '../../hooks/useDebounce';
import { AutoCompleteInput, PlacesInputContainer, Prediction, PredictionsContainer } from './placesAutoComplete.styled';

interface IPlacesAutoComplete {
  placeValue: string;
  onPlaceSelected: (lat: number, lng: number, placeName: string, placeType: any) => void;
}

const PLACE_TYPES = ['sublocality', 'locality', 'administrative_area_level_3', 'postal_code'];

const PlacesAutoComplete = ({ placeValue, onPlaceSelected }: IPlacesAutoComplete) => {
  const [predictions, setPredictions] = useState([]);

  const [input, setInput] = useState(placeValue);

  const debouncedInput = useDebounce<string>(input);

  const [showLocationOptions, setShowLocationOptions] = useState(false);

  const predictionsRef = useRef();
  const containerRef = useRef();

  useOnClickOutside(containerRef, () => setShowLocationOptions(false));

  const onInputFocus = () => {
    setShowLocationOptions(true);
  };

  useEffect(() => {
    try {
      if (showLocationOptions) {
        const autocompleteService = new window.google.maps.places.AutocompleteService();

        autocompleteService.getPlacePredictions(
          {
            input,
            types: PLACE_TYPES,
            componentRestrictions: { country: 'gb' },
          },
          (placesPredictions: Array<any>) => {
            setPredictions(placesPredictions);
          },
        );
      }
    } catch (err) {
      console.error({ err });
    }
  }, [debouncedInput]);

  useEffect(() => {
    setInput(placeValue);
  }, [placeValue]);

  const handleAutoCompletePlaceSelected = (placeId: string) => {
    setShowLocationOptions(false);

    if (window.google) {
      const PlacesService = new window.google.maps.places.PlacesService(predictionsRef.current);
      try {
        PlacesService.getDetails(
          {
            placeId,
            fields: ['geometry', 'name', 'types'],
            region: 'gb',
          },
          (place) => {
            onPlaceSelected(
              place.geometry.location.lat(),
              place.geometry.location.lng(),
              place.name,
              place.types.find((type: string) => PLACE_TYPES.includes(type)),
            );
          },
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <PlacesInputContainer ref={containerRef}>
      <AutoCompleteInput
        value={input ?? ''}
        placeholder="Where?"
        onFocus={onInputFocus}
        onChange={(e) => setInput(e.currentTarget.value)}
      />
      {!!predictions?.length && showLocationOptions && (
        <PredictionsContainer>
          <div ref={predictionsRef}></div>
          {predictions?.map((prediction) => (
            <Prediction key={prediction.place_id} onClick={() => handleAutoCompletePlaceSelected(prediction.place_id)}>
              <span>{prediction.description}</span>
            </Prediction>
          ))}
        </PredictionsContainer>
      )}
    </PlacesInputContainer>
  );
};

export default PlacesAutoComplete;
