import React from 'react';
import ReactDOMClient from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './app/app';

const tagManagerArgs = {
  gtmId: 'GTM-NGG29LG',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<App />);
