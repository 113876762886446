import React from 'react';

import AppWrapper from '../../components/appWrapper/appWrapper';
import useScrollToLocation from '../../hooks/useScrollToLocation';
import BusinessPartner from './components/businessPartner/businessPartner';
import CharityPartners from './components/charityPartners/charityPartners';
import HeroSection from './components/heroSection/heroSection';
import HighlightsSection from './components/highlightsSection/highlightsSection';
import NewsletterSubscription from './components/newletterSubscription/newsletterSubscription';

const Home = () => {
  useScrollToLocation();

  return (
    <AppWrapper>
      <HeroSection />
      <HighlightsSection />
      <BusinessPartner />
      <NewsletterSubscription />
      <CharityPartners />
    </AppWrapper>
  );
};

export default Home;
